// Ref: https://github.com/Engaged-Insurance/client-quote/tree/master/src/screens/RFQStatuses/QuotesAvailable/QuoteCard.js#L587
export const siBreakupNameMap = {
  'BuildingValue': 'Building',
  'BasementValue': 'Basement',
  'PlinthFoundation': 'Plinth & Foundation',
  'BoundaryWalls': 'Boundary Walls, etc.',
  'PlantMachinery': 'Plant & Machinery',
  'FurnitureFixture': 'Furniture & Fixture',
  'Electronics': 'Electronic Installations',
  'OfficeEquipment': 'Office Equipment (including IT systems)',
  'ValuableContents': 'Valuable contents',
  'Other': 'Other contents',
  'MaxValSingleItem': 'Maximum value of a single content item',
  'Stock': 'Stock'
}