import React from "react"
import styled from "styled-components"
import { Text } from "../core/commonExports"
import Modal, { ModalHeaderContainer } from "../Modal"

import { getAmtInNumWords } from "../../utils/getAmtInNumWords"
import roundOffNum from "../../utils/roundOffNum"
import { siBreakupNameMap } from "./sharedData"

import CircleChecked from "../../assets/images/circle-checked.svg"

const DataItem = ({title, data,}) => {
  return (
    <DataItemContainer>
      <Text fontSize="14px" mfontSize="12px" lineHeight="17px" mlineHeight="15px" fontWeight="500" color="#8D8D8D" style={{margin: "0", marginBottom: "4px"}}>
        {title}
      </Text>
      <Text fontSize="20px" mfontSize="14px" lineHeight="24px" mlineHeight="16px" fontWeight="bold" style={{margin: "0"}}>
        {data}
      </Text>
    </DataItemContainer>
  )
}

const SIBreakdownModal = ({policyDetails, handleModalClose}) => {
  const cover = getAmtInNumWords(`${roundOffNum(policyDetails.cover)}`)
  const siBreakdown = policyDetails?.SumInsuredBreakdown || {}
  const siBreakdownKeys = Object.keys(siBreakdown)
  return (
    <Modal mobileBottomAlign handleClose={handleModalClose}>
      <ModalHeaderContainer>
        <Text 
          fontSize="16px" 
          mfontSize="14px" 
          lineHeight="20px" 
          mlineHeight="17px" 
          fontWeight="bold" 
          color="#333333" 
          style={{margin: "0", textTransform: "uppercase", textAlign: "left", flex: "1"}}
        >
          Total Sum insured Breakup
        </Text>
      </ModalHeaderContainer>
      <ModalBodyContainer>
        <GridContainer>
          <TotalSiDataItemContainer>
            <Text fontSize="14px" mfontSize="12px" lineHeight="17px" mlineHeight="15px" fontWeight="500" style={{margin: "0"}} color="#8D8D8D" mobileStyles={{fontStyle: "italic"}}>
              Total Sum Insured
            </Text>
            <Text fontSize="20px" mfontSize="16px" lineHeight="24px" mlineHeight="19px" fontWeight="bold" style={{margin: "0"}}>
              ₹{cover}
            </Text>
          </TotalSiDataItemContainer>
          <div style={{display: "flex", gap: "8px", alignItems: "center"}}>
            <CircleChecked />
            <Text fontSize="14px" mfontSize="12px" lineHeight="17px" mlineHeight="15px" fontWeight="bold" color="#8D8D8D" style={{margin: "0"}}>
              Proposal Accepted
            </Text>
          </div>
          {siBreakdownKeys.map(siItem => {
            const siAmount = siBreakdown?.[siItem]
            if (!siAmount) return
            return (
              <DataItem 
                title={siBreakupNameMap?.[siItem]} 
                data={`₹${getAmtInNumWords(`${roundOffNum(siAmount)}`)}`}
              />
            )
          })}
        </GridContainer>
      </ModalBodyContainer>
    </Modal>
  )
}

const ModalBodyContainer = styled.div`
  padding: 30px;
  width: 100%;
  @media screen and (max-width: 768px) {
    padding: 30px 14px;
  }
`

const DataItemContainer = styled.div`
  width: fit-content; 
  text-align: left;
  margin-left: 24px;
`

const TotalSiDataItemContainer = styled(DataItemContainer)`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: 0;
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.125rem 2.25rem;
  @media screen and (max-width: 768px) {
    gap: 1.25rem;
  }
`

export default SIBreakdownModal