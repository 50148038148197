// Ref: https://github.com/Engaged-Insurance/client-quote/blob/master/src/utilities/convertAmountToWords.js
const convertToPlural = number => (number > 1 ? 's' : '')

export const getAmtInNumWords = (amountStr, withComma=false, singular = false) => {
	// extract number string using regex
	let amountStrArr = amountStr.match(/([0-9,.])+/g)
	// temporary copy to use later
	let tempAmountStrArr = amountStrArr
	if (amountStrArr) {
		amountStrArr = amountStrArr.map(item => {
			// convert to a proper number here
			let num = withComma ? item.split(',').join('') : item
			// console.log(item)
			return Math.floor(num / 10000000)
				? Math.round((num / 10000000 + Number.EPSILON) * 100) / 100 +
						' Crore' +
						(!singular ? convertToPlural(Math.floor(num / 10000000)) : '')
				: Math.floor(num / 100000)
				? Math.round((num / 100000 + Number.EPSILON) * 100) / 100 +
				  ' Lakh' +
				  convertToPlural(Math.floor(num / 100000))
				: Math.floor(num / 1000)
				? Number(num)
						.toFixed(0)
						.toString()
						.replace(/(?=(\d{3})(?!\d))/g, ',') // add a ',' only 3 digits before by searching for empty string
				: Number(num)
				? Number(num).toFixed(0)
				: item
		})
		// replace the occurances in the original string using the temporary one as a reference
		tempAmountStrArr.forEach(
			(item, key) => (amountStr = amountStr.replace(item, amountStrArr[key]))
		)
	}
	return amountStr
}