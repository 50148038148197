export const dummyQuotes = {
  "quotes": [
    {
      "id": "XXXX",
      "Status": "ACCEPTED",
      "Insurer": "",
      "TotalPremium": "XXXX",
      "Selected": false,
      "HasPremiumBreakdown": true
    }
    // {
    //   "id": "63a9bd22d2fc7ff5f490ae83",
    //   "Status": "ACCEPTED",
    //   "Insurer": "The New India Assurance Co. Ltd.",
    //   "TotalPremium": 802,
    //   "Addons": [
    //     {
    //       "Name": "BURGLARY_THEFT",
    //       "Amount": 143,
    //       "BaseAmount": 121,
    //       "SumInsured": 0,
    //       "IGST": 22,
    //       "Rejected": false
    //     }
    //   ],
    //   "Selected": false,
    //   "HasPremiumBreakdown": true
    // },
    // {
    //   "id": "63a9bd23d2fc7ff5f490ae89",
    //   "Status": "ACCEPTED",
    //   "Insurer": "United India Insurance",
    //   "TotalPremium": 615.502982,
    //   "Addons": [
    //     {
    //       "Name": "BURGLARY_THEFT",
    //       "Amount": 119,
    //       "BaseAmount": 101,
    //       "SumInsured": 0,
    //       "IGST": 18,
    //       "Rejected": false
    //     }
    //   ],
    //   "Selected": false,
    //   "HasPremiumBreakdown": true
    // }
  ],
  "cover": "XXXXX",
  "selfDeclaration": false,
  "policyName": "XXXX",
  "duration": "XXXX",
  "SumInsuredBreakdown": {
    "PlantMachinery": 0,
    "BuildingValue": "XXXXX",
    "OfficeEquipment": 0,
    "FurnitureFixture": 0,
    "Electronics": 0,
    "Other": 0
  }
}

export const dummyQuote = {
  "id": "XXXX",
  "Status": "ACCEPTED",
  "Insurer": "",
  "TotalPremium": "XXXX",
  "Selected": false,
  "HasPremiumBreakdown": false
}

export const dummyPolicyDetails = {
  "cover": "XXXXX",
  "selfDeclaration": false,
  "policyName": "XXXX",
  "duration": "XXXX",
  "SumInsuredBreakdown": {
    "PlantMachinery": 0,
    "BuildingValue": 0,
    "OfficeEquipment": 0,
    "FurnitureFixture": 0,
    "Electronics": 0,
    "Other": 0
  }
}