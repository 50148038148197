import React from "react"
import styled from "styled-components"

import { Button } from "../core/Button"
import { Text } from "../core/commonExports"
import { OnDesktop, OnMobile } from "../DesktopMobileComps"
import { CityStateStatsCard, CoverageCard, NearestBusinessCard } from "../LeadFormSteps/RegionalStatsCards"
import { Loader } from "../core/Loader"
import RegionalStatsSection from "../LeadFormSteps/RegionalStatsSection"

import { useLeadFormData } from "../../context/LeadFormContext"
import useIsMobile from "../../hooks/useIsMobile"
import useRegionalStats from "../LeadFormSteps/hooks/useRegionalStats"
import { dummyPolicyDetails, dummyQuote } from "./dummyData"
import { coverageValues, insurerImages, insurerLegalNames, insurerMetrics } from "./insurerData"
import { getAmountWithCommas } from "../../utils/getAmountWithCommas"
import roundOffNum from "../../utils/roundOffNum"
import { fullFormsMap } from "./policyData"
import { getAmtInNumWords } from "../../utils/getAmtInNumWords"
import calculateTotalPremium from "../../utils/calculateTotalPremium"
import EditDetails from "./EditClientDetails"
import {siBreakupNameMap} from "./sharedData"
import SIBreakdownModal from "./SIBreakdownModal"
import { generatePaymentLink, getPaymentMandate, selectQuote } from "../../api/selfServeAPIs"
import { scrollToTop } from "../../utils/scrollToTop"
import { getPolicyTypeFullForm } from "../../utils/getPolicyTypeFullForm"

import ChevronDown from "../../assets/images/chevron_down.svg";
import EditIcon from "../../assets/images/edit.svg";
import ClockIcon from "../../assets/images/clock.svg";
import StarIcon from "../../assets/images/star.svg";
import DownloadIcon from "../../assets/images/download.svg";
import CardIcon from "../../assets/images/card.svg";
import GlobeIcon from "../../assets/images/globe-light.svg";
import LoadingDots from "../../assets/loadingDots.gif";

const screens = {
  quoteSelection: "quote-selection",
  finalizeQuote: "finalize-quote"
}

const DataItem = ({title, data, italicsTitleMobile=false, desktopStyles={}, mobileStyles={} }) => {
  return (
    <DataItemContainer desktopStyles={desktopStyles} mobileStyles={mobileStyles}>
      <Text fontSize="14px" mfontSize="10px" lineHeight="17px" mlineHeight="12px" fontWeight="500" style={{margin: "0"}} mobileStyles={italicsTitleMobile ? {fontStyle: "italic"} : {}}>
        {title}
      </Text>
      <Text fontSize="20px" mfontSize="10px" lineHeight="24px" mlineHeight="12px" fontWeight="bold" desktopStyles={{margin: "0", marginTop: "7px"}} mobileStyles={{margin: "0", marginTop: "4px"}}>
        {data}
      </Text>
    </DataItemContainer>
  )
}

const ClientDetailsComp = ({data, handleEditDetails, showEdit = true}) => {
  return (
    <ClientDetailsContainer>
      <DataItem italicsTitleMobile title="Customer Name" data={data?.customerName || ''} />
      <DataItem italicsTitleMobile title="Business Name" data={data?.companyName || ''} />
      <OnDesktop style={{width: "auto"}}>
        <DataItem italicsTitleMobile title="Phone Number" data={data?.mobile || ''} />
      </OnDesktop>
      <DataItem italicsTitleMobile title="Pincode" data={data?.pincode || ''} />
      {showEdit && (
        <>
          <OnDesktop style={{width: "auto", flexGrow: "1"}}>
            <Button 
              onClick={handleEditDetails}
              uppercaseLabel={false} 
              label="Review or edit details"
              btnIcon={<ChevronDown className="icon-right-arrow" />}
              btnIconAlign="right"
              style={{border: "2px solid #B3B3B3", borderRadius: "8px", width: "240px", height: "58px", padding: "14px", background: "transparent", color: "#4c4c4c", boxShadow: "none", float: "right", fontWeight: "600"}}
            />
          </OnDesktop>
          <OnMobile>
            <Button 
              onClick={handleEditDetails}
              uppercaseLabel={false} 
              label="Review/Edit Details" 
              btnIcon={<EditIcon className="icon-left" />}
              style={{background: "transparent", border: "none", boxShadow: "none", color: "#2854E7", fontWeight: "bold", fontSize: "10px", lineHeight: "12px", padding: "0"}}
            />
          </OnMobile>
        </>
      )}
    </ClientDetailsContainer>
  )
}

const PolicyDetailsComp = ({policyDetails}) => {
  const desktopStyles={background: "#FBFCFF", borderRadius: "8px", minWidth: "216px", padding: "20px"}
  const mobileStyles={background: "#FBF8FF", borderRadius: "8px", padding: "12px 8px", minWidth: "50px"}

  const policyDuration = coverageValues?.find(data => data.value === policyDetails?.duration)?.name || '-'
  const quoteExpiry = (
    <>
      <ClockIcon className="icon-left clock-icon" style={{verticalAlign: "bottom"}} /> 
      2 days
    </>
  )

  const duration = ( 
    <DataItem 
      desktopStyles={desktopStyles} 
      mobileStyles={mobileStyles} 
      title="Duration" 
      data={policyDuration}
    />
  )

  const quotesExpiry = (
    <DataItem 
      desktopStyles={{...desktopStyles, background: "#FFF0EF"}} 
      mobileStyles={{...mobileStyles, background: "FFF0EF", flex: "1"}} 
      title="Quotes Expire In" 
      data={<strong style={{color: "#C32A1F"}}>{quoteExpiry}</strong>} 
    />
  )
  const policyType = (
     <DataItem 
      desktopStyles={desktopStyles} 
      mobileStyles={{...mobileStyles, width: "100%"}} 
      title="Policy Type" 
      data={
        <>
          {policyDetails?.policyName} &nbsp;
          <OnMobile style={{width: "auto", height: "2px"}}><br/></OnMobile>
          <span style={{color: "rgba(153, 153, 153, 1)"}}>
            ({fullFormsMap?.[policyDetails?.policyName] || '-'})
          </span>
        </>
      }
    />
  )

  return (
    <>
      <OnDesktop>
        <PolicyDetailsContainer>
          {duration}
          {quotesExpiry}
          {policyType}
        </PolicyDetailsContainer>
      </OnDesktop>
      <OnMobile>
        <PolicyDetailsContainer>
          <div style={{display: "flex", gap: "7px"}}>
            {duration}
            {quotesExpiry}
          </div>
          {policyType}
        </PolicyDetailsContainer>
      </OnMobile>
    </>
  )
}

const QuotesListHeader = ({headerStyle={}, includeBorder = true}) => {
  return (
    <QuotesListHeaderContainer style={headerStyle}>
      <Text fontSize="14px" mfontSize="11px" lineHeight="17px" mlineHeight="13px" fontWeight="700" style={{margin: "0", letterSpacing: "0.05em", textAlign: "center", width: "min(245px, 100%)"}} color="#fff">Insurer</Text>
      {includeBorder && <VeriticalBorder style={{minHeight: "26px", borderColor: "#346E82", marginLeft: "0"}} />}
      <Text fontSize="14px" mfontSize="11px" lineHeight="17px" mlineHeight="13px" fontWeight="700" style={{margin: "0", letterSpacing: "0.05em", textAlign: "center", width: "min(245px, 100%)", flexGrow: "1"}} color="#fff">Cover</Text>
      {includeBorder && <VeriticalBorder style={{minHeight: "26px", borderColor: "#346E82"}} />}
      <Text fontSize="14px" mfontSize="11px" lineHeight="17px" mlineHeight="13px" fontWeight="700" style={{margin: "0", letterSpacing: "0.05em", textAlign: "center", width: "min(288px, 100%)"}} color="#fff">Premium</Text>
    </QuotesListHeaderContainer>
  )
}

const SiBreakdownData = ({title, data}) => {
  return (
    <DataItemContainer>
      <Text fontSize="12px" mfontSize="10px" lineHeight="14.5px" mlineHeight="12px" fontWeight="500" color="#8D8D8D" style={{margin: "0"}}>
        {title}
      </Text>
      <Text fontSize="14px" mfontSize="12px" lineHeight="17px" mlineHeight="15px" fontWeight="bold" color="#333" style={{margin: "0"}}>
        {data}
      </Text>
    </DataItemContainer>
  )
}

const Quote = ({data, policyDetails, onQuoteSelect, onShowSiBreakup, dummyQuote = false}) => {
  const insurer = data?.Insurer || ''
  let siInWords = policyDetails?.cover ? getAmtInNumWords(`${roundOffNum(policyDetails?.cover || 0)}`) : ''
  
  const durationValue = coverageValues.find(item => item.value === policyDetails?.duration) || {months: 12} 
  const premium = calculateTotalPremium(data)
  let commaSeparatedPremium = `₹ ${getAmountWithCommas(premium)}`
  let premiumPerDay = premium ? roundOffNum(premium / durationValue?.months / 30) : 0
  let estYear = insurerMetrics?.[insurer]?.EstablishedOn || ''

  if (dummyQuote) {
    siInWords = "XXXX"
    commaSeparatedPremium = "₹ XXXX"
    premiumPerDay = "XXXX"
    estYear = "XXXX"
  }

  const siBreakdown = policyDetails?.SumInsuredBreakdown || {}
  const siBreakdownKeys = Object.keys(siBreakdown)
  // const showSiBreakup = siBreakdownKeys?.length > 2

  const insurerDetails = (
    <QuoteDataContainer>
      <InsurerImgContainer>
        {insurerImages?.[insurer]}
      </InsurerImgContainer>
      {estYear ? (
        <div style={{display: "flex", gap: "8px", alignItems: "center"}}>
          <Text fontSize="14px" mfontSize="11px" lineHeight="17px" mlineHeight="13px" fontWeight="500" style={{margin: "0"}}>
            Est. {estYear} 
          </Text>
          <OnMobile style={{width: "auto"}}>
            <StarIcon />
          </OnMobile>
          {!dummyQuote && <EstYearTime>{new Date().getFullYear() - Number(estYear)} years ago</EstYearTime>}
        </div>
        
      ) : null}
    </QuoteDataContainer>
  )
  const siDetails = (
    <QuoteDataContainer desktopMinWidth="300px" style={{position: "relative", flex: "1"}}>
      <SiDetailsContainer>
        <div>
          <Text fontSize="20px" mfontSize="16px" lineHeight="24px" mlineHeight="19.36px" fontWeight="bold" style={{margin: "0"}}>{siInWords ? `₹${siInWords}` : '-'}</Text>
          <Text fontSize="14px" mfontSize="10px" lineHeight="17px" mlineHeight="12px" fontWeight="500" style={{margin: "0"}}>Total Sum Insured</Text>
        </div>
        {siBreakdownKeys?.length ? (
          <CompactSiBreakdown>
            {siBreakdownKeys?.slice(0, 2)?.map(siItem => {
              const siAmount = siBreakdown?.[siItem]
              if (!siAmount || dummyQuote) return
              return (
                 <SiBreakdownData 
                  title={siBreakupNameMap?.[siItem]} 
                  data={`₹${getAmtInNumWords(`${roundOffNum(siAmount)}`)}`}  
                />
              )
            })}
          </CompactSiBreakdown>
        ) : null}
        <TransparentBtn 
          style={{position: "absolute", right: "0.5rem", bottom: "0"}}
          label="See All" 
          btnIcon={<ChevronDown className="icon-right-arrow icon-white" />}
          btnIconAlign="right"
          onClick={onShowSiBreakup}
        />
      </SiDetailsContainer>
    </QuoteDataContainer>
  )
  const selectQuote = (
    <QuoteDataContainer>
      <StyledButton 
        label={commaSeparatedPremium} 
        onClick={onQuoteSelect}
        btnIcon={<ChevronDown className="icon-right-arrow icon-white" />}
        btnIconAlign="right"
      />
      <Text color="#4c4c4c" fontSize="14px" mfontSize="11px" lineHeight="17px" mlineHeight="13px" fontWeight="bold" style={{fontStyle: "italic"}} desktopStyles={{margin: "0", marginTop: "12px"}} mobileStyles={{margin: "0", marginTop: "20px"}}>
        Costs ₹{premiumPerDay} per day (approx)
      </Text>
    </QuoteDataContainer>
  )

  return (
    <>
      <OnDesktop>
        <QuoteContainer>
          {insurerDetails}
          <VeriticalBorder />
          {siDetails}
          <VeriticalBorder />
          {selectQuote}
        </QuoteContainer>
      </OnDesktop>
      <OnMobile>
        <QuoteContainer>
          <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            {insurerDetails}
            <VeriticalBorder />
            {selectQuote}
          </div>
          <HorizontalBorder style={{width: "100%"}} />
          {siDetails}
        </QuoteContainer>
      </OnMobile>
    </>
  )
}

const RegionalStatsDesktop = ({pincode, city, stateVal}) => {
  const {isMobile, isDetecting} = useIsMobile()
  const {nearestBusiness, incidentStats, loading} = useRegionalStats(
    pincode, 
    city, 
    stateVal, 
    !isMobile && !isDetecting // fetch the data here only on desktop (comp is only shown on desktop)
  )

  if (loading) {
    return <div style={{minWidth: "216px", margin: "auto", display: "flex", justifyContent: "center"}}>
      <Loader />
    </div>
  }

  return (
    <OnDesktop style={{width: "auto"}}>
      <div style={{display: "flex", flexDirection: "column", gap: "20px"}}>
        <CoverageCard policy={"BSUS"} />
        <NearestBusinessCard data={nearestBusiness} />
        <CityStateStatsCard data={incidentStats} />
      </div>
    </OnDesktop>
  )
}

const SelectedQuoteDataItemMobile = ({title, data}) => {
  return(
    <DataItemContainer>
      <Text fontSize="14px" mfontSize="12px" lineHeight="17px" mlineHeight="15px" fontWeight="500" style={{margin: "0"}}>
        {title}
      </Text>
      <Text fontSize="20px" mfontSize="16px" lineHeight="24px" mlineHeight="20px" fontWeight="bold" style={{margin: "0", marginTop: "7px", marginBottom: "12px"}}>{data}</Text>
    </DataItemContainer>
  )
}

const SelectedQuoteDataItemDesktop = ({title, data}) => {
  return(
    <>
      <Text fontSize="14px" mfontSize="10px" lineHeight="17px" mlineHeight="12px" fontWeight="500" style={{margin: "0"}} color="#8D8D8D">
        {title}
      </Text>
      <Text fontSize="20px" mfontSize="16px" lineHeight="24px" mlineHeight="19.36px" fontWeight="bold" style={{margin: "0", marginBottom: "8px"}}>
        {data}
      </Text>
    </>
  )
}

const SelectedQuote = ({rfqId, selectedQuote, policyDetails, onConfirmQuote}) => {
  const insurer = selectedQuote?.Insurer || ''
  const insurerLegalName = insurerLegalNames?.[insurer] || insurer
  const siInWords = policyDetails?.cover ? getAmtInNumWords(`${roundOffNum(policyDetails?.cover || 0)}`) : ''
  const premium = calculateTotalPremium(selectedQuote)
  const premiumWithCommas = premium ? getAmountWithCommas(premium) : ''
  const premiumPerDay = premium ? roundOffNum(premium / 365) : 0

  const [isLoading, setIsLoading] = React.useState(false)
  const [paymentMandate, setPaymentMandate] = React.useState('')

  const handleQuoteConfirmation = async () => {
    let windowReference = window.open();
    setIsLoading(true)
    await selectQuote(rfqId, selectedQuote?.id)
    const result = await generatePaymentLink(rfqId)
    if (result.paymentLink) {
      setIsLoading(false)
      windowReference.location = result.paymentLink;
      onConfirmQuote()
    }
  }

  const fetchPaymentMandate = async () => {
    const result = await getPaymentMandate(rfqId, selectedQuote?.Insurer)
    if (result?.mandatePdf) {
      setPaymentMandate(result.mandatePdf)
      return result.mandatePdf
    }
    return ''
  }

  const handleDownloadPaymentMandate = async () => {
    let windowReference = window.open();
    let mandatePdf = paymentMandate
    if (!mandatePdf) {
      mandatePdf = await fetchPaymentMandate()
    }
    if (!mandatePdf) return
    windowReference.location = mandatePdf;
  }

  const insurerDetails = (
    <QuoteDataContainer style={{width: "fit-content", paddingLeft: "2rem"}}>
      <InsurerImgContainer>
        {insurerImages?.[insurer]}
      </InsurerImgContainer>
      <Button 
        type="default" 
        uppercaseLabel={false} 
        label="Download Payment Mandate" 
        btnIcon={<DownloadIcon className="icon-left" />}
        background="#fff" 
        boxShadow="none" 
        style={{padding: "4px"}} 
        fontStyles={{ color: "#333", fontSize: "14px", lineHeight: "16px"}} 
        mobileStyles={{fontSize: "12px"}}
        onClick={handleDownloadPaymentMandate}
      />
    </QuoteDataContainer>
  )
  const premiumText = premiumWithCommas ? (
    <>
      ₹{premiumWithCommas}
      <Text fontSize="14px" mfontSize="12px" lineHeight="17px" mlineHeight="14px" color="rgba(87, 186, 112, 1)" style={{margin:"0"}}>
        Costs ₹{premiumPerDay} per day
      </Text>
    </>
  ) : '-'

  const paymentMethods = (
    <div style={{display: "flex", gap: "12px", background: "#F4FFF9", borderRadius: "16px", border: "1px solid rgba(74, 161, 97, 0.4)", padding: "10px 16px", width: "fit-content"}}>
      <CardIcon />
      <Text color="#4AA161" fontSize="14px" mfontSize="14px" lineHeight="24px" mlineHeight="24px" style={{margin: "0"}}>
        All UPI/Debit/Credit cards/Netbanking supported
      </Text>
    </div>
  )

  const ctaLoadingAnim = (
    <img src={LoadingDots} alt="loading" style={{height: "inherit"}} />
  )

  const proceedToPayCTA = (
    <ProceedToPayContainer>
      <OnDesktop>
        {paymentMethods}
      </OnDesktop>
      <StyledButton 
        type="default" 
        uppercaseLabel={false} 
        style={{minWidth: "212px", float: "right", fontSize: "18px", lineHeight: "24px"}} 
        label={isLoading ? ctaLoadingAnim : "Proceed to pay" }
        btnIcon={!isLoading ? <GlobeIcon className="icon-left" /> : null}
        onClick={handleQuoteConfirmation}
      />
    </ProceedToPayContainer>
  )

  const policyName = policyDetails?.policyName || ''
  const policyType = (
    <>
      Vyapar Suraksha, <br/>
      <span style={{color: "#F78670"}}>{`${policyName} (${getPolicyTypeFullForm(policyName)})`}</span>
    </>
  )

  const onDesktop = (
    <OnDesktop>
      <QuotesListHeader headerStyle={{background: "#4C4C4C", marginBottom: "8px"}} includeBorder={false} />
      <QuoteContainer style={{padding: "16px 0", marginBottom: "20px"}}>
        {insurerDetails}
        <VeriticalBorder />
        <QuoteDataContainer style={{flex: "1"}}>
          <SelectedQuoteDataItemDesktop title="Total Sum Insured" data={`₹${siInWords}`} />
          {/* <SelectedQuoteDataItemDesktop title="Date Of Transaction" data="DD/MM/YYYY" /> */}
        </QuoteDataContainer>
        <VeriticalBorder />
        <QuoteDataContainer style={{flex: "1"}}>
          <SelectedQuoteDataItemDesktop title="Type Of Policy" data={policyType} />
          <SelectedQuoteDataItemDesktop title="Total Premium Per Year" data={premiumText} />
        </QuoteDataContainer>
      </QuoteContainer>
      {proceedToPayCTA}
    </OnDesktop>
  )

  const onMobile = (
    <OnMobile>
      <div style={{margin: "auto", width: "fit-content"}}>
        {insurerDetails}
      </div>
      <div style={{marginTop: "16px"}} />
      <SelectedQuoteDataItemMobile title="Paying To:" data={insurerLegalName || '-'} />
      <SelectedQuoteDataItemMobile title="Total Sum Insured" data={`₹${siInWords}`} />
      <SelectedQuoteDataItemMobile title="Premium Amount (per annum):" data={`₹${premiumWithCommas}`} />
      {/* <SelectedQuoteDataItemMobile title="Date Of Transaction:" data="DD/MM/YYYY" /> */}
      <SelectedQuoteDataItemMobile title="Type Of Policy" data={policyType} />
      {paymentMethods}
      {/* <div style={{position: "absolute", bottom: "0", width: "100%"}}> */}
      {proceedToPayCTA}
      {/* </div> */}
    </OnMobile>
  )

  return (
    <>
      {onDesktop}
      {onMobile}
    </>
  )
}

// master component
const QuoteSelection = ({
  rfqId,
  selectedQuote,
  setSelectedQuote,
  policyDetails,
  handleQuoteConfirmation,
  quotes,
  areQuotesLoading,
  handleEditDetails
}) => {
  const [leadFormData, dispatch] = useLeadFormData()
  const {pincode, city, stateVal} = leadFormData || {}
  
  const [screen, setScreen] = React.useState(screens.quoteSelection)
  const [showSiBreakdownModal, setShowSiBreakdownModal] = React.useState(false)

  const isQuoteConfScreen = screen === screens.finalizeQuote

  const handleShowSiModal = () => setShowSiBreakdownModal(true)
  const handleCloseSiModal = () => setShowSiBreakdownModal(false)

  const handleQuoteSelection = (quote) => {
    setSelectedQuote(quote)
    setScreen(screens.finalizeQuote)
    scrollToTop()
  }

  const handleQuoteChange = () => {
    setSelectedQuote(null)
    setScreen(screens.quoteSelection)
  }

  let content = (
    <>
      <Text fontSize="36px" mfontSize="18px" lineHeight="43.57px" mlineHeight="21.78px" fontWeight="bold" style={{textAlign: "center"}} desktopStyles={{marginBottom: "44px"}} mobileStyles={{marginBottom: "16px"}} color="#8668AD">
        Your Quotes are here 🎊
      </Text>
      <OnMobile>
        <RegionalStatsSection selfServe pincode={pincode} city={city} stateVal={stateVal} />
        <HorizontalBorder style={{margin: "1rem 0", borderColor: "#cccccc"}} />
      </OnMobile>
      <OnDesktop>
        <ClientDetailsComp handleEditDetails={handleEditDetails} data={leadFormData} />
      </OnDesktop>
      <OnMobile>
        <div style={{display: "flex", gap: "8px", marginBottom: "16px"}}>
          <PolicyDetailsComp policyDetails={policyDetails} />
          <ClientDetailsComp handleEditDetails={handleEditDetails} data={leadFormData} />
        </div>
      </OnMobile>
      <div style={{display: "flex", gap: "24px", marginTop: "24px"}}>
        <div style={{flex: "1", width: "100%"}}>
          <OnDesktop>
            <PolicyDetailsComp policyDetails={policyDetails} />
          </OnDesktop>
          <QuotesListContainer>
            <QuotesListHeader />
              {quotes?.map(quote => 
                <Quote 
                  data={quote} 
                  policyDetails={policyDetails} 
                  onQuoteSelect={() => handleQuoteSelection(quote)} 
                  onShowSiBreakup={handleShowSiModal}
                />
              )}
            </QuotesListContainer>
        </div>
        <RegionalStatsDesktop pincode={pincode} city={city} stateVal={stateVal} />
        {showSiBreakdownModal ? 
          <SIBreakdownModal 
            policyDetails={policyDetails} 
            handleModalClose={handleCloseSiModal}
          /> 
          : null
        }
      </div>
    </>
  )

  if (isQuoteConfScreen) {
    content = (
      <>
        <div style={{display: "flex", alignItems: "center", gap: "16px", marginBottom: "24px"}}>
          <BackBtn label="" btnIcon={<ChevronDown className="back-icon" />} onClick={handleQuoteChange} />
          <Text 
            fontSize="36px" 
            mfontSize="18px" 
            lineHeight="43.57px"
            mlineHeight="21.78px" 
            fontWeight="bold" 
            desktopStyles={{margin: "0", textAlign: "left"}} 
            mobileStyles={{margin: "0", marginLeft: "5.5rem", textAlign: "center", color: "#4c4c4c"}} 
            color="#8668AD"
          >
            Payment
          </Text>
        </div>
        <OnDesktop>
          <ClientDetailsComp showEdit={false} handleEditDetails={handleEditDetails} data={leadFormData} />
        </OnDesktop>
        <div style={{display: "flex", gap: "24px", marginTop: "24px"}}>
          <SelectedQuote rfqId={rfqId} selectedQuote={selectedQuote} policyDetails={policyDetails} onConfirmQuote={handleQuoteConfirmation} />
          <RegionalStatsDesktop pincode={pincode} city={city} stateVal={stateVal} />
        </div>
      </>
    )
  }

  if (areQuotesLoading) {
    content = (
      <>
        <Text fontSize="36px" mfontSize="18px" lineHeight="43.57px" mlineHeight="21.78px" fontWeight="bold" style={{textAlign: "center"}} desktopStyles={{marginBottom: "44px"}} mobileStyles={{marginBottom: "16px"}} color="#8668AD">
          Your Quotes are here 🎊
        </Text>
        <OnMobile>
          <RegionalStatsSection selfServe pincode={pincode} city={city} stateVal={stateVal} />
          <HorizontalBorder style={{margin: "1rem 0", borderColor: "#cccccc"}} />
        </OnMobile>
        <OnDesktop>
          <ClientDetailsComp handleEditDetails={handleEditDetails} data={leadFormData} />
        </OnDesktop>
        <OnMobile>
          <div style={{display: "flex", gap: "8px", marginBottom: "16px"}}>
            <PolicyDetailsComp policyDetails={dummyPolicyDetails} />
            <ClientDetailsComp handleEditDetails={handleEditDetails} data={leadFormData} />
          </div>
        </OnMobile>
        <div style={{display: "flex", gap: "24px", marginTop: "24px"}}>
          <div style={{flex: "1", width: "100%"}}>
            <OnDesktop>
              <PolicyDetailsComp policyDetails={dummyPolicyDetails} />
            </OnDesktop>
            <QuotesListContainer>
              <QuotesListHeader />
                <Quote 
                  dummyQuote
                  data={dummyQuote} 
                  policyDetails={dummyPolicyDetails} 
                />
              </QuotesListContainer>
          </div>
          <RegionalStatsDesktop pincode={pincode} city={city} stateVal={stateVal} />
        </div>
        <div style={{height: "25vh"}} />
      </>
    )
  }

  return (
    <QuotesScreenContainer isQuoteConfScreen={isQuoteConfScreen}>
      {content}
    </QuotesScreenContainer>
  )
}

const CompactSiBreakdown = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 12px;
  margin-left: 1.5rem;
  @media screen and (max-width: 768px) {
    margin: 0;
    flex-direction: column;
    gap: 12px;
  }
`

const SiDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (max-width: 768px) {
    flex-direction: row;
    gap: 1.5rem;
  }
`

const TransparentBtn = styled(Button)`
  background: transparent; 
  border: none; 
  box-shadow: none; 
  color: #2854E7; 
  font-weight: 700; 
  font-size: 12px; 
  line-height: 15px; 
  padding: 0.25rem;
  border-radius: 4px;
  min-width: 0;
`

const EstYearTime = styled.span`
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: rgba(76, 76, 76, 1);
  background: #E9EFFE;
  border: 1px solid #CDDBFE;
  border-radius: 11px;
  width: fit-content;
  padding: 4px 12px;
  @media screen and (max-width: 768px) {
    font-size: 11px;
    line-height: 13px;
    color: #4C4C4C;
    background: #fff;
    border: none;
    padding: 0;
  }
`

const ProceedToPayContainer = styled.div`
  display: flex;
  padding: 16px 36px; 
  margin-top: 20px;
  box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.13);
  border-radius: 8px;

  @media screen and (max-width: 768px) {
    /* justify-content: flex-end; */
    justify-content: center;
    padding: 8px 16px;
    /* border-top: 1px solid #F2F2F2;; */
    box-shadow: none;
    margin-top: 16px;
  }
`

const BackBtn = styled(Button)`
  width: 36px;
  height: 36px;
  padding: 0;
  min-width: 0;
  background: #F0F0FD;
  border-radius: 10px;
  box-shadow: none;

  .back-icon {
    transform: rotate(-90deg);
  }
`

const QuotesScreenContainer = styled.div`
  position: relative;
  @media screen and (max-width: 768px) {
    padding: 0.5rem 1rem;
    padding-bottom: 1rem;
    background: ${p => p.isQuoteConfScreen ? "#fff" : "#E8EBF0"}; 
    .clock-icon {
      width: 12px;
      height: 12px;
    }
  }
  .icon-right-arrow {
    margin-left: 0.25rem;
    transform: rotate(90deg);
  }
  .icon-white > path {
    fill: #fff;
  }
  .icon-left {
    margin-right: 0.25rem;
  }
`

const DataItemContainer = styled.div`
  width: fit-content; 
  text-align: left;
  ${p => p.desktopStyles ? {...p.desktopStyles} : null}

  @media screen and (max-width: 768px) {
    ${p => p.mobileStyles ? {...p.mobileStyles} : null}
  }
`

const ClientDetailsContainer = styled.div`
  display: flex;
  gap: 50px;
  width: 100%;
  background: #FAFBFF;
  padding: 14px 20px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 7px;
    background: #FBF8FF;
    border-radius: 8px;
    padding: 12px;
    width: 100%;
  }
`

const PolicyDetailsContainer = styled.div`
  display: flex;
  gap: 14px;
  width: 100%;
  background: transparent;
  margin-bottom: 24px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 7px;
    margin-bottom: 0;
  }
`

const InsurerImgContainer = styled.div`
  width: 180px;
  height: 90px;
  @media screen and (max-width: 768px) {
    width: 131px;
    height: 65px;
  }
`

const QuoteContainer = styled.div`
  display: flex;
  /* justify-content: ; */
  align-items: center;
  padding: 6px 0px;
  background: #FFFFFF;
  border: 1px solid #F2F2F2;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const VeriticalBorder = styled.div`
  width: 0;
  min-height: 120px;
  border: 1px solid #F2F2F2;
  margin: 0;
`
const HorizontalBorder = styled.div`
  height: 0;
  min-width: 300px;
  border: 1px solid #F2F2F2;
  margin-bottom: 0.5rem;
`

const QuoteDataContainer = styled.div`
  padding: 0 40px;
  min-width: ${p => p.desktopMinWidth || "188px"};
  /* @media screen and (max-width: 900px) {
    min-width: 0px;
  } */
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0 14px;
    min-width: 140px;
  }
`

const QuotesListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media screen and (max-width: 768px) {
    gap: 16px;
  }
`

const QuotesListHeaderContainer = styled(QuoteContainer)`
  /* justify-content: space-around; */
  padding: 10px 16px;
  background: #02475E;
  box-shadow: 0px -1px 25px rgba(0, 0, 0, 0.08);
  @media screen and (max-width: 768px) {
    display: none;
  }
`

const StyledButton = styled(Button)`
  background: #F78670;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  width: 223px;
  height: 58px;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  @media screen and (max-width: 768px) {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    width: 125px;
    height: 48px;
    border-radius: 4px;
  }
`

export default QuoteSelection